import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

/**
 * In this functional component a fullscreen <BackgroundImage />  is created.
 * @param className   string    className(s) from styled-components.
 * @param children    nodes     Child-components.
 * @return {*}
 * @constructor
 */
// eslint-disable-next-line react/prop-types
const FullBackground = ({ className, children }) => {
  const { desktop } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "background.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  // Single Image Data
  const imageData = desktop.childImageSharp.fluid;

  return (
    <div className="w-full h-full flex items-center justify-center fixed top-0 left-0 bg-black">
      <BackgroundImage
        Tag="section"
        className={className}
        fluid={imageData}
        backgroundColor="#040e18"
        title="Gravity studios"
        id="fullscreenbg"
        role="img"
        aria-label="Gravity studios"
        preserveStackingContext
      >
        {children}
      </BackgroundImage>
    </div>
  );
};

// const StyledFullBackground = styled(FullBackground)`
//   width: 100%;
//   height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

export default FullBackground;
