import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "triangle-pink.png" }) {
        childImageSharp {
          fixed(width: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <div className="absolute">
      <Img
        fixed={data.file.childImageSharp.fixed}
        alt="alt-img-tag"
        style={{
          mixBlendMode: "hard-light",
          backgroundBlendMode: "hard-light",
        }}
      />
    </div>
  );
};

export default Image;
