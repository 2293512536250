import * as React from "react";
import Particles from "react-particles-js";
import BackgroundImage from "../components/BackgroundImage";
import TriangleImage from "../components/TriangleImage";
import TriangleGlowImage from "../components/TriangleGlowImage";
import Logo from "../components/Logo";
import Astronaut from "../components/Astronaut";
import particleConfig from "../particlesjs-config";

const IndexPage = () => (
  <main className="bg-black text-white flex items-center justify-center w-full h-full bg-background bg-cover bg-fixed">
    <BackgroundImage className="w-full h-full flex flex-col md:flex-row items-start md:items-center md:justify-center">
      <div className="fixed top-6 flex justify-between items-center text-gray text-14x17.07 w-full px-10">
        <Logo />
        {/* <span className="text-14x21 text-gray-light font-semibold">
          MISSION
        </span> */}
      </div>
      <Particles
        params={particleConfig}
        className="w-full h-full fixed top-0 left-0 z-10"
      />
      <title>Home Page</title>
      <div className="flex flex-col w-full md:w-1/2 md:h-2/3 px-10 pt-20 md:p-0">
        <div className="flex flex-col items-center justify-start gap-6 md:h-1/2">
          <h1 className="text-48x59 font-bold max-w-xl">
            Engineering Decisions Are Business Decisions
          </h1>
          <span className="text-24x29.26 text-gray-light max-w-xl">
            You need someone who knows tech and has your back.
          </span>
        </div>
      </div>
      <div className="w-full md:w-1/2 flex items-start justify-start md:h-2/3 relative">
        <TriangleImage />
        <TriangleGlowImage />
        <Astronaut />
      </div>
      <footer className="fixed bottom-6 flex justify-between items-center text-gray text-14x17.07 w-full px-10">
        <span>&copy; grvty.studio</span>
        <a
          type="email"
          href="mailto:contact@grvty.studio"
          className="cursor-pointer z-20"
        >
          contact@grvty.studio
        </a>
      </footer>
    </BackgroundImage>
  </main>
);

export default IndexPage;
